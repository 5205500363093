import SearchGlass from "@/assets/Search.svg";
import Error from "@/assets/Error.svg";
import SearchHappy from "@/assets/SearchHappy.svg";

interface NoResultsMessageProps {
  readonly visible: boolean;
  readonly variant: string;
  readonly message?: string;
}

const messageConfig = {
  loading: {
    icon: SearchHappy,
    defaultMessage: "Loading data...",
  },
  noResults: {
    icon: SearchGlass,
    defaultMessage: "No logs found for current period and filters.",
  },
  error: {
    icon: Error,
    defaultMessage: "An error occurred while fetching the data.",
  },
  noData: {
    icon: SearchGlass,
    defaultMessage: "No data available for the selected period.",
  },
};

export const NoResultsMessage = ({
  visible,
  variant,
  message,
}: NoResultsMessageProps) => {
  if (!visible) return null;

  const config = messageConfig[variant];

  return (
    <div className="flex flex-col gap-4 items-center justify-center">
      <div className="flex justify-center">
        <img src={config.icon} alt="Message Icon" />
      </div>
      <div className="text-black text-sm">
        {message || config.defaultMessage}
      </div>
    </div>
  );
};
